export default [
  {
    title: 'STT',
    dataIndex: 'rowIndex',
    scopedSlots: { customRender: 'rowIndex' },
    align: 'center',
    width: 50
  },
  {
    slots: { title: 'actionTitle' },
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
    width: 80,
    align: 'center'
  },
  {
    title: 'Mã đơn',
    dataIndex: 'preOrderNo',
    scopedSlots: { customRender: 'preOrderNo' },
    align: 'center',
    ellipsis: true,
    width: 150
  },
  {
    title: 'Tổng kiện hàng theo đơn đặt hàng',
    dataIndex: 'totalCartonNo',
    scopedSlots: { customRender: 'totalCartonNo' },
    align: 'right',
    ellipsis: true,
    width: 200
  },
  {
    title: 'Tên file',
    dataIndex: 'fileName',
    scopedSlots: { customRender: 'fileName' },
    align: 'center',
    ellipsis: true,
    width: 180

  },
  {
    title: 'Số lượng kiện hàng',
    dataIndex: 'quantityCartonNo',
    scopedSlots: { customRender: 'quantityCartonNo' },
    align: 'right',
    ellipsis: true,
    width: 200
  },
  {
    title: 'Ngày tạo',
    dataIndex: 'createAt',
    scopedSlots: { customRender: 'createAt' },
    align: 'center',
    ellipsis: true,
    width: 180
  }
]
