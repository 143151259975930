<template>
  <main-layout>

    <template v-slot:breadcrumb>
      <a-breadcrumb separator=">">
        <a-breadcrumb-item><a href="/">Home</a></a-breadcrumb-item>
        <a-breadcrumb-item :class="'active'">Kế hoạch nhận hàng</a-breadcrumb-item>
      </a-breadcrumb>
    </template>
    <a-collapse v-model="activeSearchKey" expandIconPosition="left" style=" margin-top: 8px" class="collapse-left">
      <a-collapse-panel header="Điều kiện tìm kiếm" key="1" class="filter-item-container">
        <a-row :gutter="16">
          <a-col :xs="10" :md="10" :lg="10" :offset="1">
            <a-form-model :model="filter" ref="ruleFilter">
              <a-form-model-item
                label="Từ khóa"
                prop="keyword"
                :rules="[]">
                <a-input
                  v-model="filter.keyword"
                  placeholder="Mã đơn hàng, Mã kiện hàng">
                </a-input>
              </a-form-model-item>
            </a-form-model>
          </a-col>
          <a-col :xs="12" :md="12" :lg="12">
            <div style="display: flex; justify-content: flex-start">
              <a-button
                :loading="loading"
                type="default"
                class="btn-success uppercase"
                style="margin-left: 10px; margin-top: 23px"
                @click="resetFilter">Nhập lại
              </a-button>
              <a-button
                v-if="$auth.hasPrivilege('PLAN_RECEIVE_MANAGEMENT_READ')"
                :loading="loading"
                type="primary"
                class="btn-success uppercase"
                style="margin-left: 10px; margin-top: 23px"
                @click="search">Tìm kiếm
              </a-button>
              <a-button
                v-if="$auth.hasPrivilege('PLAN_RECEIVE_MANAGEMENT_READ')"
                :disabled="!data.length"
                :loading="loading"
                type="primary"
                style="margin-left: 10px; margin-top: 23px"
                class="btn-success uppercase"
                @click="exportExcel"
              >
                Kết xuất
              </a-button>
            </div>
          </a-col>
        </a-row>
      </a-collapse-panel>
    </a-collapse>
    <a-collapse v-model="activeResultKey" expandIconPosition="left" style=" margin-top: 8px" class="collapse-left">
      <a-collapse-panel header="Kết quả trả về" key="1" class="filter-item-container">
        <a-card style="width: 100%; border: none" class="vts-table-container">
          <a-row :gutter="16" type="flex">
            <a-col :span="24">
              <div style="display: flex; justify-content: flex-end">
                <a-button
                  v-if="$auth.hasPrivilege('PLAN_RECEIVE_MANAGEMENT_WRITE')"
                  :loading="loading"
                  type="primary"
                  class="btn-success uppercase"
                  style="margin: 10px"
                  @click="showCreate">Tạo kế hoạch
                </a-button>
              </div>
            </a-col>
            <a-col :span="24">
              <a-table
                ref="tb1"
                :columns="columns"
                :data-source="data"
                :rowKey=" (rowKey, index ) => index"
                :pagination="data.length === 0 ? false : pagination"
                :loading="loading"
                :scroll="{ x: '100%' }"
                :locale="{ emptyText: 'Chưa có dữ liệu' }"
                @change="handleTableChange"
                class="ant-table-bordered">
                <template slot="actionTitle">
                  <a-icon type="control" :style="{fontSize: '14px'}"/>
                </template>
                <template slot="rowIndex" slot-scope="text, record, index">
                  <span>{{ getTableRowIndex(pagination.pageSize, pagination.current, index) }} </span>
                </template>
                <template slot="totalCartonNo" slot-scope="text, record">
                  <span>{{ formatPriceNotZero(record.totalCartonNo) }} </span>
                </template>
                <template slot="fileName" slot-scope="text, record">
                  <a-tooltip placement="bottomLeft">
                    <template slot="title">
                      <span>{{ record.fileName }}</span>
                    </template>
                    <span>{{ record.fileName }} </span>

                  </a-tooltip>
                </template>
                <template slot="operation" slot-scope="text, record">
                  <a-popover v-if="$auth.hasPrivilege('PLAN_RECEIVE_MANAGEMENT_READ')">
                    <template slot="content">
                      <span>Chi tiết</span>
                    </template>
                    <a-icon @click="showDetail(record)" type="eye" style="margin-right: 8px; color: #086885"></a-icon>
                  </a-popover>
                  <a-popover v-if="$auth.hasPrivilege('PLAN_RECEIVE_MANAGEMENT_WRITE')">
                    <template slot="content">
                      <span>Xóa</span>
                    </template>
                    <a-icon type="delete" @click="onDeleteRow(record)" style="margin-right: 8px; color: red"></a-icon>
                  </a-popover>
                </template>
              </a-table>
            </a-col>
          </a-row>
        </a-card>
      </a-collapse-panel>
    </a-collapse>
    <create-delivery-plan
      v-if="visibleForm === true"
      :visibleForm="visibleForm"
      :modelObject="modelObject"
      @closeForm="closeForm"
    ></create-delivery-plan>
    <detail-delivery-plan
      v-if="visibleDetail === true"
      :visibleDetail="visibleDetail"
      :modelObject="modelObject"
      @closeFormDetail="closeFormDetail"
    ></detail-delivery-plan>
  </main-layout>
</template>

<script>
import MainLayout from '@/pages/layouts/MainLayout'
import resizeableTitle from '@/utils/resizable-columns'
import TableEmptyText from '@/utils/table-empty-text'
import columns from './columns'
import _merge from 'lodash/merge'
import { commonMethods, authComputed } from '@/store/helpers'
import pdf from 'vue-pdf'
import CreateDeliveryPlan from './Create'
import DetailDeliveryPlan from './Detail'
import _ from 'lodash'
import { searchDeliveryPlan, deleteDeliveryPlan } from '@/api/delivery-plan'
import moment from 'moment'
import { exportReport2 } from '@/api/report'

const ResizeableTitle = resizeableTitle(columns)
export default {
  components: {
    MainLayout,
    pdf,
    CreateDeliveryPlan,
    DetailDeliveryPlan
  },
  mixins: [TableEmptyText],
  name: 'DeliveryPlan',
  data () {
    this.components = {
      header: {
        cell: ResizeableTitle
      }
    }
    return {
      activeSearchKey: 1,
      activeResultKey: 1,
      data: [],
      pagination: {
        current: 1,
        total: 1,
        pageSize: 15,
        pageSizes: 500,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['15', '25', '50'],
        showTotal: (total) => {
          return 'Tổng số dòng ' + total
        }
      },
      loading: false,
      columns,
      visibleForm: false,
      visibleDetail: false,
      modelObject: {
        id: '',
        warehouseId: '',
        phone: '',
        imei: ''
      },
      filter: {
        keyword: ''
      },
      json_fields: {
        'STT': 'rowIndex',
        'Mã đơn': 'preOrderNo',
        'Tổng kiện hàng theo đơn đặt hàng': 'totalCartonNo',
        'Tên file': 'fileName',
        'Số lượng kiện hàng': 'quantityCartonNo',
        'Ngày tạo': 'createAt'
      },
      json_meta: [
        [
          {
            'align': 'left',
            'key': 'charset',
            'value': 'utf-8'
          }
        ]
      ]
    }
  },
  created () {
    this.getData()
  },
  mounted () {
    this.scrollBarOfTable()
  },
  computed: {
    ...authComputed
  },
  methods: {
    ...commonMethods,
    resetFilter () {
      this.$refs.ruleFilter.resetFields()
      this.getData()
    },
    search () {
      this.$refs.ruleFilter.validate(valid => {
        if (valid) {
          this.getData()
        }
      })
    },
    handleTableChange (pagination, filters, sorter) {
      this.pagination = pagination
      this.getData()
    },
    getData () {
      const params = {
        page: this.pagination.current > 0 ? this.pagination.current - 1 : 0,
        size: this.pagination.pageSize,
        keyword: this.filter.keyword
      }
      this.loading = true
      this.data = []
      searchDeliveryPlan(params).then(res => {
        this.data = this.convertPropToDisplayDate(res.data)
        this.pagination = _merge(this.pagination, this.handlePaginationData(res))
        this.loading = false
      }).catch(err => {
        const msg = this.handleApiError(err)
        this.$notification.error({
          message: '',
          description: msg,
          duration: 5
        })
      }).finally(res => {
        this.loading = false
      })
    },
    showCreate () {
      this.visibleForm = true
      this.isCreate = true
      this.isView = false
    },
    showDetail (record) {
      this.visibleDetail = true
      this.modelObject = _.cloneDeep(record)
    },
    closeForm () {
      this.visibleForm = false
      this.modelObject = {}
      this.getData()
    },
    closeFormDetail () {
      this.visibleDetail = false
      this.modelObject = {}
      this.getData()
    },
    onDeleteRow (record) {
      this.$confirm({
        title: 'Bạn muốn xóa bản ghi này?',
        okText: 'Có',
        okType: 'primary',
        cancelText: 'Không',
        onOk: () => {
          if (record.id) {
            this.deleteGL(record.id)
          }
        },
        onCancel () {
        }
      })
    },
    deleteGL (id) {
      const $this = this
      this.loading = true
      deleteDeliveryPlan(id)
        .then(rs => {
          $this.getData()
          this.$notification.success({
            message: 'Kế hoạch nhận hàng',
            description: 'Xóa kế hoạch nhận hàng thành công',
            duration: 5
          })
        })
        .catch(err => {
          const msg = this.handleApiError(err)
          this.$notification.error({
            message: 'Kế hoạch nhận hàng',
            description: msg,
            duration: 5
          })
        }).finally(res => {
          this.loading = false
        })
    },
    exportExcel () {
      this.$refs.ruleFilter.validate(valid => {
        if (valid) {
          const params = {
            fileType: 'XLSX',
            fileName: 'ke_hoach_nhan_hang',
            params: {
              keyword: this.filter.keyword
            },
            reportName: 'plan_receive_export'
          }
          this.loadingExport = true
          exportReport2(params).then(rs => {
            const date = new Date(moment())
            const fileName = 'ke_hoach_nhan_hang_' + moment(date, 'YYYY_MM_DD').format('YYYY_MM_DD') + '.xlsx'
            if (window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveBlob(rs, fileName)
            } else {
              const downloadLink = window.document.createElement('a')
              downloadLink.href = window.URL.createObjectURL(rs)
              downloadLink.download = fileName
              document.body.appendChild(downloadLink)
              downloadLink.click()
              document.body.removeChild(downloadLink)
            }
          }).catch(err => {
            const msg = this.handleApiError(err)
            this.$error({ content: msg })
          }).finally(res => {
            this.loadingExport = false
          })
        }
      })
    }

  }
}
</script>
<style lang="less">
//.ant-upload-list-item-name {
//  color: blue!important;
//}
</style>
