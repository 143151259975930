<template>
  <a-modal
    :visible="visible"
    :title="'Tạo kế hoạch'"
    @cancel="closeForm"
    :destroy-on-close="false"
    :mask-closable="false"
    :okText="'Xác nhận'"
    @ok="submitConfirm"
    :cancelText="'Đóng'"
  >
    <a-spin :spinning="loading">
      <a-card style="border: none; padding: 10px 10px">
        <a-form-model :model="form" ref="ruleForm" layout="inline">
          <a-row :gutter="16">
            <a-col :xs="24" :md="24" :lg="24">
              <a-form-model-item
                label="File kế hoạch">
                <a-upload
                  name="file"
                  :multiple="false"
                  :accept="'.xls,.xlsx,.XLSX,.XLS,application/vnd.ms-excel'"
                  :show-upload-list="true"
                  :before-upload="beforeUpload"
                  :remove="removeFile"
                >
                  <a-button :disabled="fileList.length > 0"> <a-icon type="upload" /> Tải lên file</a-button>
                </a-upload>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </a-card>
    </a-spin>
  </a-modal>
</template>
<script>
import { createDeliveryPlan } from '@/api/delivery-plan'
export default {
  components: {

  },
  props: {
    visibleForm: {
      type: Boolean,
      required: true,
      default: false
    },
    modelObject: {
      type: Object,
      required: true
    }
  },
  mounted () {
    if (this.visibleForm === true) {
      this.visible = true
    } else {
      this.visible = false
    }
  },

  computed: {
    form () {
      return this.modelObject
    }
  },
  data () {
    return {
      visible: false,
      fileList: [],
      loading: false,
      FILE_ACCEPT_EXCEl: '.xls,.xlsx,.XLSX,.XLS,application/vnd.ms-excel'
    }
  },
  created () {
  },
  methods: {
    closeForm () {
      this.visibleForm = false
      this.form = {}
      this.$emit('closeForm')
    },
    beforeUpload (data) {
      // data.status = 'done'
      const object = {
        fileName: data.name,
        fileType: data.name.substr(data.name.lastIndexOf('.')),
        dataBase64: '',
        uid: data.uid
      }
      this.getBase64(data).then(data => {
        object.dataBase64 = data.slice(data.indexOf('base64,') + 7)
      })
      this.fileList.push(object)
      return false
    },
    changeUpload () {

    },
    getBase64 (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    removeFile (file) {
      if (file) {
        this.fileList.forEach((item, index) => {
          if (item.uid === file.uid) {
            this.fileList.splice(index, 1)
          }
        })
      }
    },
    submitConfirm () {
      if (this.fileList.length === 0) {
        this.$notification.error({
          message: 'Tạo kế hoạch ',
          description: 'File kế hoạch là bắt buộc',
          duration: 5
        })
      } else {
        const params = {
          fileName: this.fileList[0].fileName,
          dataBase64: this.fileList[0].dataBase64
        }
        this.loading = true
        createDeliveryPlan(params).then(rs => {
          if (rs) {
            this.$success({ content: 'Tạo kế hoạch thành công' })
            this.closeForm()
          }
        }).catch(err => {
          const msg = this.handleApiError(err)
          this.$notification.error({
            message: 'Tạo kế hoạch',
            description: msg,
            duration: 5
          })
        }).finally(res => {
          this.loading = false
        })
      }
    }
  }
}
</script>
<style lang="less">
.ant-upload-list-item-name {
  width: 250px!important;
}
</style>
