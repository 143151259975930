<template>
  <a-modal
    :visible="visible"
    :title="'Danh sách kiện hàng trên ' + modelObject.vehicle + ' của đơn ' + modelObject.preOrderNo"
    @cancel="closeForm"
    :destroy-on-close="false"
    :mask-closable="false"
    :width="800"
    :footer="false"
  >
    <a-spin :spinning="loading">
      <a-card style="border: none; padding: 10px 10px">
        <a-form-model :model="form" ref="ruleForm" layout="inline">
          <a-row :gutter="16">
            <a-col :xs="24" :md="24" :lg="24">
              <a-table
                ref="tb1"
                :columns="columnsDetail"
                :data-source="data"
                :rowKey=" (rowKey, index ) => index"
                :pagination="data.length === 0 ? false : pagination"
                :loading="loading"
                :scroll="{ x: '100%' }"
                :locale="{ emptyText: 'Chưa có dữ liệu' }"
                @change="handleTableChange"
                class="ant-table-bordered">
                <template slot="actionTitle">
                  <a-icon type="control" :style="{fontSize: '14px'}"/>
                </template>
                <template slot="rowIndex" slot-scope="text, record, index">
                  <span>{{ getTableRowIndex(pagination.pageSize, pagination.current, index) }} </span>
                </template>
              </a-table>
            </a-col>
          </a-row>
          <a-row :gutter="16">
            <a-col :xs="24" :md="24" :lg="24">
              <div style="display: flex; justify-content: center; margin-top: 10px">
                <a-button v-if="$auth.hasPrivilege('PLAN_RECEIVE_MANAGEMENT_READ')" type="primary" @click="exportExcelDetail">Kết xuất</a-button>
                <a-button v-if="$auth.hasPrivilege('PLAN_RECEIVE_MANAGEMENT_READ')" type="primary" @click="exportExcelExported" style="margin-left: 12px">Xuất danh sách đã xuất</a-button>
                <a-button v-if="$auth.hasPrivilege('PLAN_RECEIVE_MANAGEMENT_READ')" type="primary" @click="exportExcelNotExported" style="margin-left: 12px">Xuất danh sách chưa nhập</a-button>
              </div>
            </a-col>
          </a-row>
        </a-form-model>
      </a-card>
    </a-spin>
  </a-modal>
</template>
<script>
import columnsDetail from './columnsDetail'
import { findByIdDeliveryPlan } from '@/api/delivery-plan'
import { exportReport2 } from '@/api/report'
import moment from 'moment'
import _merge from 'lodash/merge'
export default {
  components: {

  },
  props: {
    visibleDetail: {
      type: Boolean,
      required: true,
      default: false
    },
    modelObject: {
      type: Object,
      required: true
    }
  },
  mounted () {
    if (this.visibleDetail === true) {
      this.visible = true
    } else {
      this.visible = false
    }
  },

  computed: {
    form () {
      return this.modelObject
    }
  },
  data () {
    return {
      columnsDetail,
      visible: false,
      data: [],
      loading: false,
      pagination: {
        current: 1,
        total: 1,
        pageSize: 15,
        pageSizes: 500,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['15', '25', '50'],
        showTotal: (total) => {
          return 'Tổng số dòng ' + total
        }
      }
    }
  },
  created () {
    if (this.modelObject) {
      this.getData()
    }
  },
  methods: {
    closeForm () {
      this.visible = false
      this.form = {}
      this.$emit('closeFormDetail')
    },
    handleTableChange (pagination, filters, sorter) {
      this.pagination = pagination
      this.getData()
    },
    getData () {
      findByIdDeliveryPlan(this.modelObject.id).then(rs => {
        if (rs) {
          this.data = rs
          this.pagination = _merge(this.pagination, this.handlePaginationData(rs))
        }
      })
    },
    exportExcelDetail () {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          const params = {
            fileType: 'XLSX',
            fileName: 'chi_tiet_ke_hoach_nhan_hang',
            params: {
              planReceiveId: this.modelObject.id,
              fileName: this.modelObject.fileName,
              preOrderNo: this.modelObject.preOrderNo
            },
            reportName: 'plan_receive_detail_export'
          }
          this.loadingExport = true
          exportReport2(params).then(rs => {
            const date = new Date(moment())
            const fileName = 'chi_tiet_ke_hoach_nhan_hang_' + moment(date, 'YYYY_MM_DD').format('YYYY_MM_DD') + '.xlsx'
            if (window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveBlob(rs, fileName)
            } else {
              const downloadLink = window.document.createElement('a')
              downloadLink.href = window.URL.createObjectURL(rs)
              downloadLink.download = fileName
              document.body.appendChild(downloadLink)
              downloadLink.click()
              document.body.removeChild(downloadLink)
            }
          }).catch(err => {
            const msg = this.handleApiError(err)
            this.$error({ content: msg })
          }).finally(res => {
            this.loadingExport = false
          })
        }
      })
    },
    exportExcelExported () {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          const params = {
            fileType: 'XLSX',
            fileName: 'Danh_sach_kien_hang_da_xuat_cua_' + this.modelObject.vehicle + '_cua_don_' + this.modelObject.preOrderNo,
            params: {
              planReceiveId: this.modelObject.id,
              vehicle: this.modelObject.vehicle,
              fileName: this.modelObject.fileName,
              preOrderNo: this.modelObject.preOrderNo
            },
            reportName: 'rp_plan_receive_detail_exported'
          }
          this.loadingExport = true
          exportReport2(params).then(rs => {
            // const date = new Date(moment())
            const fileName = 'Danh_sach_kien_hang_da_xuat_cua_' + this.modelObject.vehicle + '_cua_don_' + this.modelObject.preOrderNo + '.xlsx'
            if (window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveBlob(rs, fileName)
            } else {
              const downloadLink = window.document.createElement('a')
              downloadLink.href = window.URL.createObjectURL(rs)
              downloadLink.download = fileName
              document.body.appendChild(downloadLink)
              downloadLink.click()
              document.body.removeChild(downloadLink)
            }
          }).catch(err => {
            const msg = this.handleApiError(err)
            this.$error({ content: msg })
          }).finally(res => {
            this.loadingExport = false
          })
        }
      })
    },
    exportExcelNotExported () {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          const params = {
            fileType: 'XLSX',
            fileName: 'Danh_sach_kien_hang_chua_nhap_cua_' + this.modelObject.vehicle + '_cua_don_' + this.modelObject.preOrderNo,
            params: {
              planReceiveId: this.modelObject.id,
              vehicle: this.modelObject.vehicle,
              fileName: this.modelObject.fileName,
              preOrderNo: this.modelObject.preOrderNo
            },
            reportName: 'rp_plan_receive_detail_not_yet_import'
          }
          this.loadingExport = true
          exportReport2(params).then(rs => {
            // const date = new Date(moment())
            const fileName = 'Danh_sach_kien_hang_chua_nhap_cua_' + this.modelObject.vehicle + '_cua_don_' + this.modelObject.preOrderNo + '.xlsx'
            if (window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveBlob(rs, fileName)
            } else {
              const downloadLink = window.document.createElement('a')
              downloadLink.href = window.URL.createObjectURL(rs)
              downloadLink.download = fileName
              document.body.appendChild(downloadLink)
              downloadLink.click()
              document.body.removeChild(downloadLink)
            }
          }).catch(err => {
            const msg = this.handleApiError(err)
            this.$error({ content: msg })
          }).finally(res => {
            this.loadingExport = false
          })
        }
      })
    }
  }
}
</script>
